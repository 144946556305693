import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { QrReader } from "react-qr-reader";
import CONFIG from "../constanta/config";
import Cookies from "js-cookie";

const PopupAddUser = (props) => {
  const [msgerror, setMsgerror] = useState("");
  const [msgsuskes, setMsgsukses] = useState("");
  const [msgName, setMsgName] = useState("");
  const [loading, setLoading] = useState(false);
  const [sukses, setSukses] = useState(false);

  const handleSubmit = () => {
    let name = getValue();
    if (name === "") {
      setMsgName("Please type your name");
      return;
    }

    setLoading(true);
    setSukses(false);

    const payload = {
      name: name,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    console.log(Cookies.get("token"));

    axios
      .post(CONFIG.URL + "/event/user", form_data, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setSukses(true);
          setMsgsukses("Success add user");
        } else {
          setMsgerror("Error add user please try again.");
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const getValue = () => {
    let name = window.document.getElementById("input_name").value;
    return name;
  };

  return (
    <>
      <form className="w-full">
        <div className="absolute z-20 top-0 left-0 w-screen h-screen bg-[#000000b3]"></div>
        <div className="absolute py-20 z-50  top-0 left-0 w-screen h-screen flex flex-col justify-center items-center">
          <div className="p-4 bg-white rounded-lg w-full max-w-lg">
            <div className="flex flex-col space-y-2 mb-10">
              <div className=" text-black text-center font-bold text-2xl uppercase">
                ADD USER
              </div>
            </div>
            <div className=" flex flex-col w-full max-w-lg">
              {!sukses && (
                <>
                  <div className="text-black mb-1">Name:</div>
                  <input
                    id="input_name"
                    placeholder="Type your name"
                    autocomplete="off"
                    className=" w-full  min-w-[160px] px-2 py-2 bg-white border-2 border-black rounded-lg text-black"
                  />
                </>
              )}
              {msgName !== "" && (
                <div className="w-full mt-2 max-w-lg text-left px-2 rounded-md bg-white text-red-500">
                  {msgName}
                </div>
              )}

              {msgerror !== "" && !sukses && (
                <div className="mt-10 w-full max-w-lg text-center py-2 px-2 rounded-md my-2 bg-red-500 text-white font-bold">
                  {msgerror}
                </div>
              )}

              {msgsuskes !== "" && (
                <div className="w-full max-w-lg text-center py-2 px-2 rounded-md my-2  text-green-500 font-bold">
                  {msgsuskes}
                </div>
              )}
            </div>

            {!loading ? (
              <>
                {sukses ? (
                  <div className=" w-full flex justify-center items-center mt-10 space-x-5">
                    <button
                      type="button"
                      onClick={() => props.onSuccess()}
                      className="min-w-[160px] px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
                    >
                      OK
                    </button>
                  </div>
                ) : (
                  <div className=" w-full flex justify-center items-center mt-10 space-x-5">
                    <button
                      type="button"
                      onClick={() => props.onClose()}
                      className="min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
                    >
                      CANCEL
                    </button>

                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="min-w-[160px] px-10 py-2 bg-blue-500 rounded-lg text-white font-bold"
                    >
                      SUBMIT
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className=" w-full flex justify-center items-center mt-10 space-x-5">
                <button
                  type="button"
                  onClick={() => props.onSuccess()}
                  className="min-w-[160px] px-10 py-2 bg-transparent rounded-lg text-black font-bold"
                >
                  Loading, please wait...
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default PopupAddUser;

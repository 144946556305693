import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import CONFIG from "../constanta/config";
import Cookies from "js-cookie";

const useReport = () => {
  const [data, setData] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(CONFIG.URL + "/event/report/event", {
        headers: {
          token: Cookies.get("token"),
        },
      });

      if (response?.data?.status === "SUCCESS") {
        // let localReport = JSON.stringify(response.data?.data);
        // window.localStorage.setItem("LocalReport", localReport);

        setData(response.data?.data);
      }
    } catch (error) {}
  }, []);

  const getData = () => {
    let localReport = window.localStorage.getItem("LocalReport");
    localReport = JSON.parse(localReport);
    setData(localReport);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { fetchData, data };
};

export default useReport;

import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../hook/useQuery";
import Cookies from "js-cookie";

const PageMenu = (props) => {
  const query = useQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 px-5"
        style={{
          background: `url('${props?.config?.background}') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col justify-center items-center pb-20">
          <div className="text-white font-bold mb-10 text-xl uppercase">
            {query.get("event")}
          </div>
          <div className="flex flex-col justify-center items-center space-y-5 w-full">
            <a
              href={`/login-gate`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              CHECK IN / CHECK OUT
            </a>

            {/* <a
              href={`/redeem?event=${query.get("event")}`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
            >
              REDEEM
            </a> */}

            {/* <a
              href={`/checkout?event=${query.get("event")}&id_gate=${query.get("id_gate")}`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
            >
              CHECK OUT
            </a> */}

            <a
              href={`/voting`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              VOTING
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageMenu;

import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import PageHome from "./pages/home";
import PageCheckin from "./pages/checkin";
import PageRedeem from "./pages/redeem";
import PageCheckout from "./pages/checkout";
import PageLogin from "./pages/login";
import PageEvent from "./pages/event";
import StatusInet from "./components/status_inet";
import PageMenu from "./pages/menu";
import PageLoginVoting from "./pages/login-voting";
import PageVoting from "./pages/voting";
import PageListMenu from "./pages/list-menu";
import axios from "axios";
import CONFIG from "./constanta/config";
import Cookies from "js-cookie";

function App() {
  const [totalCheckin, setTotalCheckin] = useState(0);
  const [totalCheckout, setTotalCheckout] = useState(0);
  const [totalRedeem, setTotalRedeem] = useState(0);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    getConfig();
  }, []);

  const checkLocalCheckin = () => {
    let checkin = window.localStorage.getItem("LocalCheckin");
    if (checkin) {
      setTotalCheckin(JSON.parse(checkin).length);
    } else {
      setTotalCheckin(0);
    }
  };

  const checkLocalCheckout = () => {
    let checkout = window.localStorage.getItem("LocalCheckout");
    if (checkout) {
      setTotalCheckout(JSON.parse(checkout).length);
    } else {
      setTotalCheckout(0);
    }
  };

  const checkLocalRedeem = () => {
    let redeem = window.localStorage.getItem("LocalRedeem");
    if (redeem) {
      setTotalRedeem(JSON.parse(redeem).length);
    } else {
      setTotalRedeem(0);
    }
  };

  const checkLocal = () => {
    // checkLocalCheckin();
    // checkLocalCheckout();
    // checkLocalRedeem();
  };

  const getConfig = async () => {
    try {
      const response = await axios.get(CONFIG.URL + "/config");

      if (response?.data?.status === "SUCCESS") {
        setConfig(response.data.config);
      }
    } catch (error) {}
  };

  if (config == null) {
    return <></>;
  }

  return (
    <>
      <StatusInet
        totalCheckin={totalCheckin}
        totalCheckout={totalCheckout}
        totalRedeem={totalRedeem}
        checkLocal={() => checkLocal()}
      />
      {/* <div className="pt-8"></div> */}

      <div className="overflow-y-auto">
        <Routes>
          <Route path="/" element={<PageMenu config={config} />} />
          <Route path="/list-menu" element={<PageListMenu config={config} />} />
          <Route path="/login-gate" element={<PageLogin config={config} />} />
          <Route
            path="/login-voting"
            element={<PageLoginVoting config={config} />}
          />
          <Route path="/voting" element={<PageVoting config={config} />} />
          <Route path="/checkin" element={<PageCheckin config={config} />} />
          <Route path="/checkout" element={<PageCheckout config={config} />} />
        </Routes>
      </div>
    </>
  );
}

export default App;

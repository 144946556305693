import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import CONFIG from "../constanta/config";
import Cookies from "js-cookie";

const useUser = () => {
  const [data, setData] = useState(null);
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(CONFIG.URL + "/user?limit=10000000", {
        headers: {
          token: Cookies.get("token"),
        },
      });

      if (response?.data?.status === "SUCCESS") {
        // let localUser = JSON.stringify(response.data?.data);
        // window.localStorage.setItem("LocalUser", localUser);

        setData(response.data?.data);
      }
    } catch (error) {}
  }, []);

  return { fetchData, data };
};

export default useUser;

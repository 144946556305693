import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useQuery } from "../../hook/useQuery";
import axios from "axios";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import useCheckInet from "../../hook/useCheckInet";
import StatusInet from "../../components/status_inet";
import moment from "moment/moment";
import useUser from "../../hook/useUser";
import useReport from "../../hook/useReport";
import PopupSearch from "../../components/popup_search";
import InputSearch from "../../components/input_search";
import InputDOB from "../../components/input_dob";
import PopupAddUser from "../../components/popup_add_user";

const PageCheckout = (props) => {
  const localReport = useReport();
  const checkInet = useCheckInet();
  // const localUser = useUser();
  const query = useQuery();
  const [state, setState] = useState("idle");
  const [showQR, setShowQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoAction, setInfoAction] = useState(false);
  const [user, setUser] = useState(null);
  const [sukses, setSukses] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDOB, setSelectedDOB] = useState(null);
  const [msgerror, setMsgerror] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [msgCheckin, setMsgCheckin] = useState("");

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (checkInet.isOnline) {
  //     localReport.fetchData();
  //   }

  //   getReport();
  // }, [checkInet.isOnline]);

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      navigate("/login-gate");
    }

    if (query.get("id_gate") === null) {
      navigate("/login-gate");
    }

    // localUser.fetchData();
  }, []);

  const handleSuccess = (qr) => {
    // if (checkInet.isOnline) {
    //   checkDataUser(qr);
    // } else {
    //   checkDataUserOffline(qr);
    // }
  };

  const handleFailed = () => {
    setShowQR(false);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("failed");
  };

  const handleCloseScan = () => {
    setShowQR(false);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("idle");
  };

  const handleOpenScan = () => {
    setShowQR(true);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("process");
  };

  // OFFLINE MODE
  const checkDataUserOffline = (qr) => {
    setShowQR(false);
    setShowSearch(false);
    setLoading(true);
    let users = window.localStorage.getItem("LocalUser");
    users = JSON.parse(users);

    let data = users.find((v) => v.qr_code === qr);
    if (data) {
      let event = data.event.find((item) => item.gate === query.get("event"));
      if (event) {
        setUser(data);

        if (event.checkin_time === "0000-00-00 00:00:00") {
          setState("success");
        } else {
          // if (event.checkout_time === "0000-00-00 00:00:00") {
          //   setState("exist");
          // } else {
          //   setState("reenter");
          // }

          setState("reenter");
        }
      } else {
        setState("failed");
      }

      setLoading(false);
    } else {
      setLoading(false);
      setState("failed");
    }
  };

  const handleCheckinOffline = () => {
    setSukses(false);
    setState("process");
    setLoading(true);

    let arr = [];
    const payload = {
      qr_code: user?.qr_code,
      id_gate: query.get("id_gate"),
      checkin_time: moment(Date.now()).format("YYYY-MM-DD hh:mm:ss"),
    };

    let checkin = window.localStorage.getItem("LocalCheckin");
    if (checkin) {
      checkin = JSON.parse(checkin);
      arr = [...checkin];

      let exist = arr.find((item) => item.qr_code === payload.qr_code);
      if (!exist) {
        arr.push(payload);

        //update local user
        let users = window.localStorage.getItem("LocalUser");
        users = JSON.parse(users);
        let _users = [...users];
        let _user = _users.find((v) => v.qr_code === payload.qr_code);
        let _event = _user.event.find((v) => v.gate === query.get("event"));
        _event.checkin_time = payload.checkin_time;

        window.localStorage.setItem("LocalUser", JSON.stringify(_users));
        //update local user
      }
    } else {
      arr.push(payload);

      //update local user
      let users = window.localStorage.getItem("LocalUser");
      users = JSON.parse(users);
      let _users = [...users];
      let _user = _users.find((v) => v.qr_code === payload.qr_code);
      let _event = _user.event.find((v) => v.gate === query.get("event"));
      _event.checkin_time = payload.checkin_time;

      window.localStorage.setItem("LocalUser", JSON.stringify(_users));
      //update local user
    }

    window.localStorage.setItem("LocalCheckin", JSON.stringify(arr));

    setInfoAction(true);
    setLoading(false);
    setSukses(true);
  };

  // OFFLINE MODE

  const handleCheckin = () => {
    if (checkInet.isOnline) {
      handleCheckinOnline();
    } else {
      handleCheckinOffline();
    }
  };

  const checkDataUser = () => {
    setState("process");
    setMsgerror("");
    setShowQR(false);
    setShowSearch(false);
    setLoading(true);

    const payload = {
      name: selectedData?.name,
      birth_date: selectedDOB,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .get(CONFIG.URL + "/user/" + selectedData?.id, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          let event = response.data?.data[0]?.event[0];
          setUser(response.data?.data[0]);
          setState("success");

          if (event.checkout_time === "0000-00-00 00:00:00") {
            if (event.checkin_time !== "0000-00-00 00:00:00") {
              setMsgCheckin("Allowed to Checkout");
            } else {
              setMsgCheckin("Haven't Checked in");
            }
          } else {
            if (moment(event.checkin_time) < moment(event.checkout_time)) {
              setMsgCheckin("Already Checked Out");
            } else {
              setMsgCheckin("Allowed to Checkout");
            }
          }
        } else {
          setMsgerror("User not found");
        }
        setLoading(false);
      })
      .catch(function (error) {
        handleCloseScan();
        setLoading(false);
      });
  };

  const handleCheckinOnline = () => {
    setSukses(false);
    setState("process");
    setLoading(true);

    const payload = {
      id_user: user?.id,
      id_gate: query.get("id_gate"),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/checkout", form_data, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        setInfoAction(true);
        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          setSukses(true);
          // localUser.fetchData();
          localReport.fetchData();
        } else {
          setSukses(false);
        }
      })
      .catch(function (error) {
        setLoading("false");
        setSukses(false);
      });
  };

  const handleActionFailed = () => {
    setState("success");
    setInfoAction(false);
  };

  const getReport = () => {
    if (checkInet.isOnline) {
      try {
        let _event = localReport?.data?.find(
          (v) => v.gate === query.get("event")
        );
        return _event.checkin;
      } catch (error) {
        return "-";
      }
    } else {
      let _localReport = window.localStorage.getItem("LocalReport");
      _localReport = JSON.parse(_localReport);
      try {
        let _event = _localReport?.find((v) => v.gate === query.get("event"));
        return _event.checkin;
      } catch (error) {
        return "-";
      }
    }
  };

  const handleSuccessSearch = (data) => {
    setSelectedData(data);
  };

  const handleSuccessDOB = (dob) => {
    setSelectedDOB(dob);
  };

  const checkDisabled = () => {
    if (selectedData !== null) {
      return false;
    } else {
      return true;
    }
  };

  const handleSuccessAdd = (data) => {
    setShowAddUser(false);
    // localUser.fetchData();
  };

  // if (localUser?.data === null || localUser?.data?.length === 0) {
  //   return (
  //     <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
  //       Loading...
  //     </div>
  //   );
  // }

  return (
    <>
      {/* {showAddUser && (
        <PopupAddUser
          onClose={() => setShowAddUser(false)}
          onSuccess={() => handleSuccessAdd()}
        />
      )} */}

      {loading && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
            Loading...
          </div>
        </>
      )}

      {infoAction && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
            {sukses ? (
              <>
                <span className="text-green-500 font-bold">
                  Check Out Success
                </span>
                <button
                  onClick={() => handleCloseScan()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
                >
                  OK
                </button>
              </>
            ) : (
              <>
                <span className="text-red-500 font-bold">Check Out Failed</span>
                <button
                  onClick={() => handleActionFailed()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
                >
                  OK
                </button>
              </>
            )}
          </div>
        </>
      )}

      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK OUT"
          event={query.get("event")}
        />
      )}

      <div
        className="w-full min-h-screen  flex flex-col justify-center items-center bg-gray-200 px-0 py-5"
        style={{
          background: `url('${props?.config?.background}') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        {!loading && state !== "process" && (
          <div className="flex flex-col justify-center items-center mb-10">
            <div className="text-white font-semibold mb-2">CHECK OUT</div>
          </div>
        )}

        {state === "idle" && (
          <div className="flex flex-col justify-center items-center w-5/6 md:w-1/2">
            <InputSearch
              onSuccess={(data) => handleSuccessSearch(data)}
              data={selectedData}
            />

            {/* <InputDOB onSuccess={(data) => handleSuccessDOB(data)} /> */}

            {/* <a
              href={`/home?event=${query.get("event")}&id_gate=${query.get(
                "id_gate"
              )}`}
              className="min-w-[160px] mt-4 px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
            >
              GO HOME
            </a> */}

            {msgerror !== "" && (
              <div className="w-full max-w-lg text-center py-2 px-2 rounded-md my-2 bg-red-500 text-white font-bold">
                {msgerror}
              </div>
            )}

            <button
              disabled={checkDisabled()}
              onClick={() => checkDataUser()}
              className={`${
                checkDisabled() ? "bg-gray-500" : "bg-green-500"
              } mt-4 min-w-[160px] px-10 py-2  rounded-lg text-white font-bold`}
            >
              SUBMIT
            </button>
          </div>
        )}

        {state === "success" && (
          <div className="flex flex-col justify-center items-center pb-0 w-full">
            <div
              className={`${
                msgCheckin === "Haven't Checked in" || msgCheckin === "Already Checked Out"
                  ? " bg-red-500"
                  : " bg-green-500"
              } rounded-lg text-white font-bold mb-6 text-xl px-10 py-5 w-full max-w-lg text-center`}
            >
              {msgCheckin}
            </div>

            <div className="text-white flex flex-col font-bold mb-10 mt-8 text-2xl text-center">
              <span className="text-4xl"> {user?.name}</span>
              <span className="text-lg text-gray-300 mt-2">
                {`${
                  user?.birth_date !== "0000-00-00"
                    ? "(" + moment(user?.birth_date).format("DD/MM/YYYY") + ")"
                    : ""
                }`}
              </span>
              <span className="text-lg text-gray-300">{user?.status}</span>
            </div>

            {msgCheckin !== "Haven't Checked in" && msgCheckin !== "Already Checked Out" && (
              <button
                onClick={() => handleCheckinOnline()}
                className="min-w-[160px]  px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
              >
                CHECK OUT
              </button>
            )}

            <button
              onClick={() => handleCloseScan()}
              className="mt-5 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
            >
              CANCEL
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PageCheckout;

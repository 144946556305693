import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { QrReader } from "react-qr-reader";
import { BsFileEarmarkArrowDownFill } from "react-icons/bs";
import moment from "moment";
import axios from "axios";
import CONFIG from "../constanta/config";
import Cookies from "js-cookie";

const InputSearch = (props) => {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState([]);
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {};

  const handleGetUser = async () => {
    const response = await axios.get(CONFIG.URL + "/user?search=" + watch("search"), {
      headers: {
        token: Cookies.get("token"),
      },
    });

    if (response?.data?.status === "SUCCESS") {
      // let localUser = JSON.stringify(response.data?.data);
      // window.localStorage.setItem("LocalUser", localUser);

      setUsers(response.data?.data);
    }
    else{
      setUsers([]);
    }
  };

  useEffect(() => {
    if (watch("search").length >= 1) {
      handleGetUser();
      // let arr = [...users];

      // let filter = [];

      // filter = arr.filter((item) =>
      //   item.name.toLowerCase().includes(watch("search").toLowerCase())
      // );
      setShow(true);
      // setFilter(filter);
    } else {
      setShow(false);
      // setFilter([]);
      props.onSuccess(null);
    }
  }, [watch("search")]);

  const handleSelect = (item) => {
    let inputName = window.document.getElementById("input_name");
    if (inputName) {
      inputName.value = item.name;
    }

    setShow(false);
    props.onSuccess(item);
  };

  return (
    <>
      <form className="w-full">
        <div className=" flex flex-col justify-between items-center">
          <div className="relative flex flex-col w-full max-w-lg">
            <div className="text-white mb-1">Name:</div>
            <input
              id="input_name"
              {...register("search")}
              autocomplete="off"
              placeholder="Type your name"
              className="w-full mb-5 min-w-[160px] px-2 py-2 bg-whiwhiteet border-2 border-white rounded-lg text-black"
            />
            {/* {filter.length === 0 && (
              <div className="text-white text-center">
                User not found.
              </div>
            )} */}
            <div className="absolute z-50 top-20 text-black flex flex-col overflow-y-auto max-h-56 w-full bg-white">
              {show &&
                users?.map((item, key) => {
                  return (
                    <div
                      onClick={() => handleSelect(item)}
                      key={key}
                      className="cursor-pointer py-3 border-b border-b-black"
                    >
                      <div className="flex flex-col">
                        <span className="text-lg px-5">
                          {" "}
                          {item.name}{" "}
                          {`${
                            item.birth_date !== "0000-00-00"
                              ? "(" +
                                moment(item.birth_date).format("DD/MM/YYYY") +
                                ")"
                              : ""
                          }`}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default InputSearch;
